<template>
  <div class="v4-awards-container">
    <div class="prize-container-section1">
      <div class="prize-s1-inner" id="awardspheader">
        <div class="title scrollObj">
          <p>
            We has been awarded<br />
            over 250 prizes
          </p>
          <p>超过250项全球奖项荣誉的嘉许和鼓励</p>
        </div>
        <div
          class="main-body"
          :class="{
            scrollObj: $bowserMobile
          }"
        >
          <div
            class="prize-map"
            :class="{
              scrollObj: $bowserMobile
            }"
          >
            <img v-if="$bowserMobile" src="@/assets/images/demo4/mobile-award-map.jpg" alt="" />
            <img v-else src="@/assets/images/demo4/award-map.png" alt="" />
          </div>
          <div class="prize-summary">
            <p>
              全球酒店设计巨头排行第十六位、中国十强室内设计机构、美国建筑大师奖、美国酒店设计奖、
              国际设计奖、国际地产设计奖、国际酒店与地产设计奖、德国标志性设计奖、意大利A'设计奖、世界建筑节奖。
            </p>
            <p>
              Ranked in the 2019 Hospitality Giants top 16, China Top 10 Interior Design
              Organizations, Architecture MasterPrize, Hospitality Dsign Awards, International
              Design Award, International Property Awards, International Hotel & Property Awards,
              Iconic Awards, A’Design Award, World Architecture Festival.
            </p>
          </div>
        </div>
      </div>
      <div class="scroll-down">
        <div class="line"></div>
        <p>scroll</p>
      </div>
    </div>
    <div class="awards__inner">
      <div class="page__title">awards</div>
      <div class="awards-main-body">
        <div class="awards-content-container" v-if="awards">
          <div class="content__year-container" v-for="(award, index) in awards" :key="index">
            <template v-if="$bowserMobile">
              <div class="current__year scrollObj">
                <p>{{ award.year }}</p>
              </div>
              <div class="content__prize-list-container">
                <div
                  class="prize__item scrollObj"
                  v-for="(awardItem, ai) in award.prizes"
                  :key="ai"
                >
                  <p class="cn">{{ awardItem.cn }}</p>
                  <p class="en" v-if="awardItem.en !== 'none'">{{ awardItem.en }}</p>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="list__item scrollObj">
                <div class="item__text" v-for="(left, l) in award.prizes.left" :key="l">
                  <p>
                    {{ left.cn }}
                  </p>
                  <p v-if="left.en !== 'none'">
                    {{ left.en }}
                  </p>
                </div>
              </div>
              <div class="list__item scrollObj">
                <div class="item__text" v-for="(middle, m) in award.prizes.middle" :key="m">
                  <p>{{ middle.cn }}</p>
                  <p v-if="middle.en !== 'none'">{{ middle.en }}</p>
                </div>
              </div>
              <div class="list__item scrollObj">
                <div class="item__text" v-for="(right, r) in award.prizes.right" :key="r">
                  <p>{{ right.cn }}</p>
                  <p v-if="right.en !== 'none'">
                    {{ right.en }}
                  </p>
                </div>
              </div>
              <div class="current__year scrollObj">
                <p v-html="formatYear(award.year)"></p>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <page-footer></page-footer>
    <mobile-back-to-top></mobile-back-to-top>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import pageFooter from '../common/page-footer.vue';
import mobileBackToTop from '../common/_mobile-back-top.vue';

export default {
  name: 'page-awards',
  components: {
    pageFooter,
    mobileBackToTop,
  },
  data() {
    return {
      awards: null,
    };
  },
  computed: {
    ...mapState(['$bowserMobile']),
  },
  watch: {
    $route: {
      handler() {
        this.$store.commit('setBackgroundColor', '#F0EFED');
        document.body.style.cssText = 'background-color:#F0EFED;';
        document.body.classList.remove('white-logo');
        this.getData();
      },
      immediate: true,
    },
  },
  methods: {
    formatYear(year) {
      if (year.indexOf('~') >= 0) {
        const years = year.split('~');
        return `${years['0']}<p>~<p/>${years['1']}`;
      }
      return year;
    },
    getData() {
      const that = this;
      that.$fly.get('/api/awards').then((res) => {
        const awards = res.data;
        that.awards = awards.map((item) => {
          const lists = item.content;
          const prizes = {
            left: [],
            middle: [],
            right: [],
          };
          lists.forEach((prize, index) => {
            if (index % 3 === 0) {
              prizes.left.push(prize);
            } else if (index % 3 === 1) {
              prizes.middle.push(prize);
            } else if (index % 3 === 2) {
              prizes.right.push(prize);
            }
          });
          return {
            id: item.id,
            year: item.year,
            prizes: that.$bowserMobile ? lists : prizes,
          };
        });
      });
    },
  },
};
</script>

<style></style>
