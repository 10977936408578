<template>
  <footer class="v4-page-footer-container">
    <div class="footer__inner" ref="footer__inner">
      <template v-if="$bowserMobile">
        <p>Copyright Shenzhen Blvd Architecture Consulting Co. Ltd.</p>
        <p>深圳毕路德建筑顾问有限公司 网站版权所有</p>
      </template>
      <template v-else>
        <p>
          Copyright Shenzhen Blvd Architecture Consulting Co. Ltd. 深圳毕路德建筑顾问有限公司
          网站版权所有 | 粤ICP备10097979号
        </p>
        <p><a href="http://www.gooob.cn" target="_blank">Web Design: Gooobrand</a></p>
      </template>
    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'page-footer',
  watch: {
    $route: {
      handler: 'init',
      immediate: true,
    },
  },
  computed: {
    ...mapState(['$bowserMobile']),
  },
  methods: {
    init() {
      const that = this;
      if (that.$route.name === 'publication' && !that.$bowserMobile) {
        that.$nextTick(() => {
          that.$refs.footer__inner.style.cssText = 'border-color:#d5d5d5;';
        });
      }
    },
  },
};
</script>

<style></style>
