<template>
  <div
    class="v4-back-bt scrollObj theme-b"
    data-extend="fixedshow"
    data-rate="0.8"
    @click="scrollToTop"
  >
    <p class="line"></p>
    <p>back top</p>
  </div>
</template>

<script>
export default {
  name: 'c_mobile-back-top',
  methods: {
    scrollToTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style></style>
